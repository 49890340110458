import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/Admin/FloatingConsulta.css";

const FloatingConsulta = ({ token, onClose }) => {
  const [data, setData] = useState([]); // Inicializar como un arreglo vacío para evitar errores
  const [loading, setLoading] = useState(true); // Estado de carga

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await axios.get(
            "https://medico24x7.com/api/Admin/Nuevo_PHP/Consulta_Admin_Boton.php",
            {
              params: { token }, // Pasar el token como query parameter
              withCredentials: true, // Si necesitas cookies
            }
          );
          setData(response.data); // Actualiza los datos
          setLoading(false); // Desactiva el estado de carga
        } catch (error) {
          console.error("Error al obtener datos:", error);
          setLoading(false); // Asegúrate de desactivar el estado de carga incluso en errores
        }
      }
    };

    fetchData();
  }, [token]);

  const [CorreoDoctor, setCorreoDoctor] = useState('');
  useEffect(() => {
    axios.get('https://medico24x7.com/api/Admin/getDoctores.php', { withCredentials: true })
      .then(response => {
        setCorreoDoctor(response.data);
      })
      .catch(error => {
        console.error('Error al obtener las consultas:', error);
      });
  }, []);

  const handleDownload = async (id) => {
    // Llama al script PHP directamente para forzar la descarga
    const url = `https://medico24x7.com/api/Admin/descargar_archivo.php?id=${id}`;
    // Crea un enlace temporal y simula un clic
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', ''); // Esto asegura que el navegador entienda que es una descarga
    document.body.appendChild(a);
    a.click(); // Simula el clic en el enlace
    document.body.removeChild(a); // Elimina el enlace
  };

  //Función para actualizar una consulta
  const actualizarConsulta = (consultaId) => {
    const tipo_consulta = document.getElementById(`tipo_consulta_${consultaId}`).value;
    const estado_consulta = document.getElementById(`estado_consulta_${consultaId}`).checked ? 1 : 0;

    const consulta = data.find(c => c.id === consultaId);
    const correo = consulta.correo;

    const formData = new FormData();
    formData.append('consulta_id', consultaId);
    formData.append('correo', correo);
    formData.append('tipo_consulta', tipo_consulta);
    formData.append('estado_consulta', estado_consulta);

    axios.post('https://medico24x7.com/api/Admin/actualizar_consulta.php', formData, { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          alert('Consulta actualizada con éxito');
        } else {
          alert('Error al actualizar la consulta: ' + response.data.message);
          console.log(response.data);
        }
      })
      .catch(error => {
        console.error('Error al actualizar la consulta:', error);
        alert('Ocurrió un error al actualizar la consulta.');
      });
  };


  const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('https://medico24x7.com/api/DropBox/getImagen_consulta_admin.php', {
                  params: { token }, // Pasar el token como query parameter
                  withCredentials: true
                });
                setImages(response.data.images);
            } catch (error) {
                console.error('Error al obtener las imágenes:', error);
            }
        };
        fetchImages();
    }, [token]);

  return (
    <>
    <div className="floating-overlay">
      <div className="floating-modal">
      <div className="floating-botton">
        <button onClick={onClose} className="floating-close-button">
          Cerrar
        </button>
      </div>
      <br />

        <table className="consultas_table">
          {loading ? (
            //animación de carga de datos
            <p>Cargando datos...</p>
          ) : data.length > 0 ? (
            data.map((consulta, index) => (
              <tbody key={consulta.id}>
                <tr>
                  <td><strong>Nombre</strong></td>
                  <td>{consulta.nombre}</td>
                </tr>
                <tr>
                  <td><strong>Apellido</strong></td>
                  <td>{consulta.apellido}</td>
                </tr>
                <tr>
                  <td><strong>Nacionalidad</strong></td>
                  <td>{consulta.nacionalidad}</td>
                </tr>
                <tr>
                  <td><strong>Cédula</strong></td>
                  <td>{consulta.cedula}</td>
                </tr>
                <tr>
                  <td><strong>Género</strong></td>
                  <td>{consulta.genero}</td>
                </tr>
                <tr>
                  <td><strong>Edad</strong></td>
                  <td>{consulta.edad}</td>
                </tr>
                <tr>
                  <td><strong>Dirección</strong></td>
                  <td>{consulta.direccion}</td>
                </tr>
                <tr>
                  <td><strong>Síntomas</strong></td>
                  <td>{consulta.sintomas}</td>
                </tr>
                <tr>
                  <td><strong>Antecedentes Familiares</strong></td>
                  <td>{consulta.antecedentes_familiares}</td>
                </tr>
                <tr>
                  <td><strong>Antecedentes Personales</strong></td>
                  <td>{consulta.antecedentes_personales}</td>
                </tr>
                <tr>
                  <td><strong>Antecedentes Quirúrgicos</strong></td>
                  <td>{consulta.antecedentes_quirurgicos}</td>
                </tr>
                <tr>
                  <td><strong>Alergias</strong></td>
                  <td>{consulta.alergias}</td>
                </tr>
                <tr>
                  <td><strong>Peso</strong></td>
                  <td>{consulta.peso}</td>
                </tr>
                <tr>
                  <td><strong>Talla</strong></td>
                  <td>{consulta.talla}</td>
                </tr>
                <tr>
                  <td><strong>Presión Arterial</strong></td>
                  <td>{consulta.presion_arterial}</td>
                </tr>
                <tr>
                  <td><strong>Frecuencia Cardiaca</strong></td>
                  <td>{consulta.frecuencia_cardiaca}</td>
                </tr>
                <tr>
                  <td><strong>Frecuencia Respiratoria</strong></td>
                  <td>{consulta.frecuencia_respiratoria}</td>
                </tr>
                <tr>
                  <td><strong>Otros Datos</strong></td>
                  <td>{consulta.otros_datos}</td>
                </tr>
                <tr>
                  <td><strong>Correo</strong></td>
                  <td>{consulta.correo}</td>
                </tr>
                <tr>
                  <td><strong>Celular</strong></td>
                  <td>{consulta.celular}</td>
                </tr>
                <tr>
                  <td><strong>Análisis</strong></td>
                  <td>{consulta.analisis}</td>
                </tr>
                <tr>
                  <td><strong>Plan</strong></td>
                  <td>{consulta.plan}</td>
                </tr>
                <tr>
                  <td><strong>Fecha de la Próxima Consulta</strong></td>
                  <td>{consulta.fecha}</td>
                </tr>
                <tr>
                  <td><strong>Hora de la Próxima Consulta</strong></td>
                  <td>{consulta.hora}</td>
                </tr>
                <tr>
                  <td><strong>Fecha de Registro</strong></td>
                  <td>{consulta.fecha_registro}</td>
                </tr>
                <tr>
                  <td><strong>Token</strong></td>
                  <td>{consulta.token}</td>
                </tr>
                <tr>
                  <td><strong>Doctor</strong></td>
                  {/* Buscamos el doctor en base al correo */}
                  <td>
                    {Array.isArray(CorreoDoctor) && CorreoDoctor.map((doc) => {
                      if (doc.correo === consulta.correo_doctor) {
                        return <p key={doc.id}>{doc.nombre}</p>;
                      }
                      return null; // Retorna null si no coincide
                    })}
                  </td>
                </tr>

                {/*Esta sección de descargar archivo estara en otro cuadro*/}
                <tr>
                  <td><strong>Archivo de exámenes</strong></td>
                  <td>
                    {consulta.archivo_examenes_encrypted ? (
                      <button onClick={() => handleDownload(consulta.id)}>Descargar</button>
                    ) : (<p>No hay archivo</p>)}
                  </td>
                </tr>

                <tr>
                  <td><strong>Tipo de consulta</strong></td>
                  <td>
                    <select
                      id={`tipo_consulta_${consulta.id}`}
                      defaultValue={consulta.tipo_de_consulta}
                      required >
                      <option value="" disabled>Seleccione el tipo de consulta</option>
                      <option value="Quitar Permiso">Quitar Permiso</option>
                      <option value="tipo_a">TIPO A</option>
                      <option value="tipo_b">TIPO B</option>
                      <option value="tipo_c">TIPO C</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td><strong>Estado de consulta</strong></td>
                  <td>
                    <label className="switch">
                      <input
                        type="checkbox"
                        id={`estado_consulta_${consulta.id}`}
                        defaultChecked={consulta.estado_consulta}
                      />
                      <span className="slider"></span>
                    </label>
                  </td>
                </tr>

                {/* Fila para actualizar consulta */}
                <tr>
                  <td><strong>Actualizar Consulta</strong></td>
                  <td>
                    <button type="button" onClick={() => actualizarConsulta(consulta.id)}>Actualizar</button>
                  </td>
                </tr>
              </tbody>
            ))
          ) : (<p>No se encontraron datos.</p>)}
        </table>
      </div>

      {images.length > 0 ? (
      <div className="floating-modal-2">
  <table className="consultas_table">
    {loading ? (
      //animación de carga de datos
      <p>Cargando datos...</p>
    ) : (
      images.map((image) => (
        <div key={image.id}>
            <img src={image.url} alt={image.nombre} className="imagen_con_admin"/>
        </div>
    )))}
  </table>
</div>
) : (<p>No se encontraron datos.</p>)}

    </div>
</>
  );
};

export default FloatingConsulta;