import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/Admin/FloatingConsulta.css";

const FloatingConsulta = ({ token, onClose }) => {
  const [data, setData] = useState([]); // Inicializar como un arreglo vacío para evitar errores
  const [loading, setLoading] = useState(true); // Estado de carga

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await axios.get(
            "https://medico24x7.com/api/AdminDoctores/Nuevo_PHP/Citas_Admin_Doctores_Boton.php",
            {
              params: { token }, // Pasar el token como query parameter
              withCredentials: true, // Si necesitas cookies
            }
          );
          setData(response.data); // Actualiza los datos
          setLoading(false); // Desactiva el estado de carga
        } catch (error) {
          console.error("Error al obtener datos:", error);
          setLoading(false); // Asegúrate de desactivar el estado de carga incluso en errores
        }
      }
    };

    fetchData();
  }, [token]);

  return (
    <div className="floating-overlay">
      <div className="floating-modal">
        <div className="floating-botton">
          <button onClick={onClose} className="floating-close-button">
            Cerrar
          </button>
        </div>
        <table className="consultas_table">
          {loading ? (
            //animación de carga de datos
            <p>Cargando datos...</p>
          ) : data.length > 0 ? (
            data.map((consulta, index) => (
              <tbody key={consulta.id}>
                <tr>
                  <td><strong>Nombre</strong></td>
                  <td>{consulta.nombre}</td>
                </tr>
                <tr>
                  <td><strong>Apellido</strong></td>
                  <td>{consulta.apellido}</td>
                </tr>
                <tr>
                  <td><strong>Correo</strong></td>
                  <td>{consulta.correo}</td>
                </tr>
                <tr>
                  <td><strong>Celular</strong></td>
                  <td>{consulta.telefono}</td>
                </tr>
                <tr>
                  <td><strong>Fecha</strong></td>
                  <td>{consulta.fecha}</td>
                </tr>
                <tr>
                  <td><strong>Hora</strong></td>
                  <td>{consulta.hora}</td>
                </tr>
                <tr>
                  <td><strong>Servicio</strong></td>
                  <td>{consulta.servicio}</td>
                </tr>
                <tr>
                  <td><strong>Motivo de la cita</strong></td>
                  <td>{consulta.mensaje}</td>
                </tr>
              </tbody>
            ))
          ) : (
            <p>No se encontraron datos.</p>
          )}
        </table>
      </div>
    </div>

  );
};

export default FloatingConsulta;