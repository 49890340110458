import { useEffect } from 'react';

function useMenuToggle(mobileMenuId, navListId) {
  useEffect(() => {
    const mobileMenu = document.getElementById(mobileMenuId);
    const navList = document.getElementById(navListId);

    const handleMenuToggle = () => {
      if (navList) {
        navList.classList.toggle('active');
      }
    };

    const handleOptionClick = () => {
      if (navList) {
        navList.classList.remove('active'); // Cierra el menú
      }
    };

    const handleClickOutside = (event) => {
      if (
        navList &&
        !navList.contains(event.target) && // Si el clic no fue dentro del navList
        !mobileMenu.contains(event.target) // Y tampoco fue dentro del botón del menú
      ) {
        navList.classList.remove('active'); // Cierra el menú
      }
    };

    if (mobileMenu) {
      mobileMenu.addEventListener('click', handleMenuToggle);
    }

    if (navList) {
      const navOptions = navList.querySelectorAll('a'); // Suponiendo que las opciones son enlaces
      navOptions.forEach((option) => {
        option.addEventListener('click', handleOptionClick);
      });
    }

    // Escucha de clics global
    document.addEventListener('click', handleClickOutside);

    return () => {
      if (mobileMenu) {
        mobileMenu.removeEventListener('click', handleMenuToggle);
      }

      if (navList) {
        const navOptions = navList.querySelectorAll('a');
        navOptions.forEach((option) => {
          option.removeEventListener('click', handleOptionClick);
        });
      }

      // Limpieza del evento global
      document.removeEventListener('click', handleClickOutside);
    };
  }, [mobileMenuId, navListId]);
}

export default useMenuToggle;

