import React, { useEffect, useState } from 'react';
import '../css/Patrocinios2.css'; // Estilos CSS 

const Patrocinios2 = () => {
    const [patrocinios, setPatrocinios] = useState(true);

    useEffect(() => {
        const togglePatrocinios = () => {
            setPatrocinios((prev) => !prev);
        };

        // Mostrar por 3 minutos (180,000 ms), ocultar por 1 minuto (60,000 ms)
        const interval = setInterval(() => {
            togglePatrocinios();
        }, patrocinios ? 180000 : 60000); // Cambiar el intervalo según el estado actual

        // Limpiar intervalo al desmontar el componente
        return () => clearInterval(interval);
    }, [patrocinios]); // Dependencia para actualizar el intervalo según el estado actual

    return (
        <>
            {patrocinios && (
                <div className="main-patrocinios-2">
                    <div className='patrocinios-2'>
                        {/*<img
                            id="logo_patrocinio"
                            src={`${process.env.PUBLIC_URL}/assets/images/Panel_Patrocinadores.png`}
                            alt="patrocinio"
                            className="img-patrocinio-2"
                        />*/}
                    </div>
                </div>
            )}
        </>
    );
};

export default Patrocinios2;
