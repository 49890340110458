import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MetodoPago from './metodo_pago';
import axios from 'axios';
import '../css/ConsultasChat.css'; // Estilos CSS 

const ConsultasVirtuales = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [images, setImages] = useState([]); // Almacena los archivos seleccionados

  const [UpImagenes, setUpImagenes] = useState(false)
  const [UpFormulario, setUpFormulario] = useState(true);

  //Inicio de sesión
  const [showmetodo_pago, setShowmetodo_pago] = useState(false);
  const openmetodo_pago = () => setShowmetodo_pago(true);

  const closemetodo_pago = useCallback(() => {
    setShowmetodo_pago(false);

    // Reinicia el tiempo restante a 3600 segundos
    setTimeLeft(3600);

    // Limpiar el temporizador anterior
    clearTimeout(timerRef.current);

    // Reinicia el temporizador
    //startTimer();
  }, []);

  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({
    correo: '',
    nombre: '',
    apellido: '',
    pago: '',
    api: '',
  });

  // Verificar sesión de usuario y pago
  useEffect(() => {
    const verificarSesion = async () => {
      try {
        const response = await axios.get('https://medico24x7.com/api/check_session_consultasChat.php', {
          withCredentials: true,
        }); // Ruta de tu archivo PHP

        // Depurar la respuesta
        //console.log('Respuesta de verificarSesion:', response.data);

        if (!response.data.usuario_id) {
          alert("No ha iniciado sesión o aún no tiene permiso para entrar en esta página.");
          navigate('/');
          return;
        }
        setUsuario({
          correo: response.data.correo,
          nombre: response.data.nombre,
          apellido: response.data.apellido,
          pago: response.data.pago,
          api: response.data.api,
        });

        setIsAuthenticated(true);

      } catch (error) {
        console.error('Error verificando la sesión:', error);
      }
    };
    verificarSesion();
  }, [navigate]);

  // Insertar y configurar el script de Tawk.to cuando el usuario esté listo
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/ConsultaChat' && usuario.correo && usuario.nombre && usuario.apellido) {
      // Definir el onLoad para Tawk_API antes de cargar el script
      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_LoadStart = new Date();

      window.Tawk_API.onLoad = function () {
        window.Tawk_API.setAttributes({
          'email': usuario.correo,
          'name': `${usuario.nombre} ${usuario.apellido}`
        }, function (error) {
          if (!error) {
            console.log("Email y nombre de usuario agregados automáticamente");
          } else {
            console.error("Error al agregar atributos a Tawk.to:", error);
          }
        });
      };

      // Insertar el script de Tawk.to
      const script = document.createElement("script");
      script.async = true;
      script.src = usuario.api;
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');
      document.body.appendChild(script);

      // Limpiar el script cuando el componente se desmonte
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [usuario, location.pathname]);


  const timerRef = useRef(null);

  const [timeLeft, setTimeLeft] = useState(() => {
    // Intenta recuperar el tiempo restante del localStorage
    const savedTime = localStorage.getItem('timeLeft');
    return savedTime ? parseInt(savedTime, 10) : 3600; // 3600 segundos (1 hora) por defecto
  });

  // Función para iniciar el temporizador
  const startTimer = useCallback(() => {
    timerRef.current = setTimeout(() => {
      openmetodo_pago(); // Llama a la función deseada
    }, timeLeft * 1000); // Convierte el tiempo a milisegundos
  }, [timeLeft]);

  useEffect(() => {
    // Inicia el temporizador al montar el componente
    startTimer();

    // Intervalo para actualizar el temporizador visual cada segundo
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        const newTime = prevTime > 0 ? prevTime - 1 : 0;
        // Guarda el tiempo restante en localStorage
        localStorage.setItem('timeLeft', newTime);

        // Aquí se verifica si el tiempo ha llegado a 0
        if (newTime === 0) {
          openmetodo_pago(); // Llama a la función deseada cuando el tiempo llega a 0
        }

        return newTime;
      });
    }, 1000);

    return () => {
      clearTimeout(timerRef.current);
      clearInterval(intervalId);
    };
  }, [startTimer]); // Dependencias vacías para ejecutar solo una vez al montar



  const salir = () => {
    const confirmacion = window.confirm("¿Ya terminaste tu consulta?");

    if (confirmacion) {
      // Hacer la llamada al archivo PHP para que se ejecute
      fetch('https://medico24x7.com/api/salir_consultaChat.php', {
        method: 'GET', // Usamos GET ya que no necesitamos enviar datos
        credentials: 'include', // Para incluir cookies en la solicitud
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json()) // Convertir la respuesta a JSON
        .then(data => {
          if (data.ok) {
            const nuevoTiempo = 3600; // O el valor que desees
            setTimeLeft(nuevoTiempo);

            // Guardar el nuevo tiempo en localStorage
            localStorage.setItem('timeLeft', nuevoTiempo);

            setTimeout(() => {
              window.location.href = '/';
            }, 100);
          } else {
            alert("Ocurrió un error en el servidor.");
          }
        })
        .catch(error => {
          console.error('Error al realizar la petición:', error);
          alert("No se pudo completar la consulta.");
        });
    } else {
      // Si el usuario presiona "Cancelar", no hacemos nada
      alert("Envio cancelado.");
    }
  };

  const initialFormData = {
    peso: '',
    talla: '',
    presion_arterial: '',
    frecuencia_cardiaca: '',
    frecuencia_respiratoria: ''
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Enviar los datos del formulario al servidor
      const response = await axios.post(
        'https://medico24x7.com/api/datos_consultaChat.php',
        formData,
        {
          withCredentials: true, // Si necesitas incluir cookies
        }
      );

      // Manejar la respuesta del servidor
      if (response.data.status === 'error') {
        // Si el servidor envía un error, mostrar el mensaje
        alert(`Error: ${response.data.message}`);
      } else {
        // Mostrar mensaje de éxito
        alert(response.data.message || "Formulario enviado correctamente.");
        setUpImagenes(true);
        setUpFormulario(false);
      }

      // Limpiar el formulario después de enviar
      //setFormData(initialFormData);
    } catch (error) {
      console.error("Error enviando los datos:", error);
      alert("Error enviando los datos.");
    }
  };


  //subir imagenes a dropbox

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convierte FileList a Array

    // Evita duplicados al agregar archivos
    const uniqueFiles = selectedFiles.filter(
      (file) => !images.some((img) => img.name === file.name)
    );

    setImages((prevImages) => [...prevImages, ...uniqueFiles]); // Agrega nuevas imágenes sin reemplazar las existentes
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (images.length === 0) {
      alert("Por favor, selecciona al menos una imagen.");
      return;
    }

    try {
      const uploadPromises = images.map(async (image) => {
        const formData = new FormData();
        formData.append('image', image);

        // Usamos axios para hacer la solicitud POST
        const response = await axios.post('https://medico24x7.com/api/DropBox/upload_consulta.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Asegúrate de que el tipo de contenido esté correctamente configurado
          },
          withCredentials: true, // Esto asegura que se envíen las cookies si es necesario
        });

        // Verifica si la respuesta es JSON válida
        const data = response.data;
        if (data.success) {
          console.log(`Imagen ${image.name}: subida con éxito.`);
        }
        else { throw new Error(data.message); }
      });

      // Espera a que todas las imágenes se suban
      await Promise.all(uploadPromises);

      alert("Imágenes subidas con éxito.");
      setImages([]); // Limpiar las imágenes cargadas

    } catch (error) {
      alert('Error al subir las imágenes: ' + error.message);
      console.error(error);
    }
  };


  if (!isAuthenticated) {
    return null;
  }

  return (
    <div>

      <div className="timerChat">
        Tiempo restante
        <br />
        de consulta: {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}
      </div>

      {showmetodo_pago && <MetodoPago closemetodo_pago={closemetodo_pago} />}



      <main>


        <div className="consulta-virtual">
          <form>
            <fieldset>
              <h1 style={{ color: 'red' }}>IMPORTANTE</h1>
              <label style={{ color: 'red', fontWeight: 'bold' }}>
                - <strong style={{ color: 'black' }}>No recargue la página</strong> o <strong style={{ color: 'black' }}>salga de ella</strong> sin haber terminado su consulta.
              </label>
              <label style={{ color: 'red', fontWeight: 'bold' }}>
                - <strong style={{ color: 'black' }}>No haga clic en el botón "Salir de consulta"</strong> sin haber terminado su consulta.
              </label>
            </fieldset>
            <br />

            <fieldset>
              <legend>Instrucciones</legend>
              <label>1. LLene el formulario y envielo.</label>
              <br />
              <label>2. Haga clic en el botón de chat ubicado en la parte inferior izquierda de la pantalla.</label>
              <br />
              <label>3. En el chat se le pedirá información como su nombre y cédula; por favor, proporciónelos y espere hasta ser atendido por el doctor en turno.</label>
              <br />
              <label>4. Una vez terminada su consulta, puede cerrar la pestaña correspondiente en su navegador o hacer clic en el botón "Salir de consulta".</label>
            </fieldset>

            <fieldset>
              <div className='salir_consultas'>
                <button type="button" id="Salir" onClick={salir}>
                  Salir de consulta
                </button>
              </div>
            </fieldset>
          </form>
        </div>

        {/* Código omitido para brevedad */}

        <div className="cont-formulario-chat">
          {!UpFormulario ? ("") : (
            <form className="form_salud" onSubmit={handleSubmit}>
              <h1>Formulario</h1>
              <p>Los campos con el signo "*" son abligatorios</p>
              <label>* Peso (Kilogragos):
                <input type="number" name="peso" value={formData.peso} onChange={handleChange} required />
              </label>
              <label>* Altura (Metros):
                <input type="number" name="talla" value={formData.talla} onChange={handleChange} required />
              </label>
              <label>Presión arterial:
                <input type="text" name="presion_arterial" value={formData.presion_arterial} onChange={handleChange} />
              </label>
              <label>Frecuencia cardíaca:
                <input type="text" name="frecuencia_cardiaca" value={formData.frecuencia_cardiaca} onChange={handleChange} />
              </label>
              <label>Frecuencia respiratoria:
                <input type="text" name="frecuencia_respiratoria" value={formData.frecuencia_respiratoria} onChange={handleChange} />
              </label>
              <button type="submit">Enviar Datos</button>
            </form>
          )}

          {!UpImagenes ? ("") : (
            <form onSubmit={handleUpload} className="upload-form">
              <p style={{ textAlign: 'center' }}><strong>Aquí puede subir imágenes relacionadas a la consulta, para que el doctor pueda verlas.</strong></p>
              <input
                type="file"
                onChange={handleFileChange}
                multiple
                className="file-input"
              />
              <button type="submit" className="upload-button">Subir Imágenes</button>
              <div className="image-list">
                {images.map((image, index) => (
                  <div key={index} className="image-item">
                    <span>{image.name}</span>
                  </div>
                ))}
              </div>
            </form>
          )}
        </div>
      </main>
    </div>
  );
};

export default ConsultasVirtuales;