import React, { useState, useRef, useEffect } from "react";
import "../css/SoundCloudPlayer.css"; // Importa el archivo CSS

const SoundCloudPlayer = () => {
  const [isVisible, setIsVisible] = useState(false); // Controla la visibilidad del iframe
  const [isMovable, setIsMovable] = useState(false); // Controla si el botón es movible
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const initialMouse = useRef({ x: 0, y: 0 });
  const isDragging = useRef(false);
  const pressTimer = useRef(null); // Temporizador para detectar mantener presionado

  // Alterna la visibilidad del iframe
  const toggleIframe = () => {
    setIsVisible(!isVisible);
  };

  //Reiniciar la posición inicial al cargar y ajustar la posición si cambia el tamaño de la ventana 
  useEffect(() => {
    const updateInitialPosition = () => {
      setPosition({
        top: window.innerHeight - 115,
        left: window.innerWidth - 110,
      });
    };

    // Establece la posición inicial al cargar
    updateInitialPosition();

    // Ajusta la posición si cambia el tamaño de la ventana
    window.addEventListener('resize', updateInitialPosition);

    return () => {
      window.removeEventListener('resize', updateInitialPosition);
    };
  }, []);

  // Activa el modo movible al mantener presionado
  const handlePressStart = (e) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;

    pressTimer.current = setTimeout(() => {
      setIsMovable(true);
      isDragging.current = true;
      initialMouse.current = { x: clientX, y: clientY };
    }, 300); // Activa el modo movible después de 300ms
  };

  // Maneja el movimiento del botón
  const handleMove = (e) => {
    if (isDragging.current) {
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;

      const deltaX = clientX - initialMouse.current.x;
      const deltaY = clientY - initialMouse.current.y;

      setPosition((prev) => ({
        top: Math.max(0, Math.min(window.innerHeight - 100, prev.top + deltaY)),
        left: Math.max(0, Math.min(window.innerWidth - 100, prev.left + deltaX)),
      }));

      initialMouse.current = { x: clientX, y: clientY };
    }
  };

  // Termina el movimiento al soltar el botón o dedo
  const handlePressEnd = () => {
    clearTimeout(pressTimer.current);
    isDragging.current = false;
    setIsMovable(false);
  };

  // Añade listeners para eventos táctiles y de mouse
  useEffect(() => {
    document.addEventListener("mousemove", handleMove);
    document.addEventListener("mouseup", handlePressEnd);
    document.addEventListener("touchmove", handleMove);
    document.addEventListener("touchend", handlePressEnd);

    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handlePressEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handlePressEnd);
    };
  }, []);

  return (
    <div>
      <button
        className="btnPlayer"
        onClick={toggleIframe}
        onMouseDown={handlePressStart}
        onTouchStart={handlePressStart}
        onMouseUp={handlePressEnd}
        onTouchEnd={handlePressEnd}
        style={{
          position: "absolute",
          top: `${position.top}px`,
          left: `${position.left}px`,
          background: isVisible ? "white" : "blue",
          border: "none",
          fontSize: "24px",
          color: isVisible ? "black" : "white",
          cursor: isMovable ? "move" : "default",
          transition: "all 0.2s ease",
        }}
      >
        <i className="fab fa-soundcloud"></i>
      </button>

      <iframe
        className="iframe"
        style={{
          opacity: isVisible ? 1 : 0,
          visibility: isVisible ? "visible" : "hidden",
          transition: "opacity 0.5s ease",
        }}
        width="100%"
        height="100%"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1900569551%3Fsecret_token%3Ds-yV5mRhwjP3T&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        title="SoundCloud Playlist"
      ></iframe>
    </div>
  );
};

export default SoundCloudPlayer;

