import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/home.css'; //

export default function NavBar() {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true); // Estado de carga
  const [fadeOut, setFadeOut] = useState(false); // Estado para manejar el fade out

  useEffect(() => {
    // Verificar si la animación ya se mostró en esta sesión
    const hasShownAnimation = sessionStorage.getItem('hasShownAnimation');

    if (!hasShownAnimation) {
      // Si la animación no se ha mostrado en esta sesión, la mostramos
      const timer = setTimeout(() => {
        setFadeOut(true); // Iniciar el desvanecimiento después de 3 segundos
      }, 6000);

      const completeFadeOut = setTimeout(() => {
        setIsLoading(false); // Ocultar el loader después del fade out
        sessionStorage.setItem('hasShownAnimation', 'true'); // Marcamos que ya se mostró después de ocultar
      }, 9000); // 3 segundo adicional para completar el desvanecimiento

      return () => {
        clearTimeout(timer);
        clearTimeout(completeFadeOut);
      };
    } else {
      // Si la animación ya se mostró, no la mostramos de nuevo
      setIsLoading(false);
    }
  }, []); // Se ejecuta solo cuando la página se carga o recarga



  //Codigo para cerrear sesion despues de 30 minutos
  const cerrarSesion = useCallback(async () => async () => {
    try {
      const response = await fetch('https://medico24x7.com/api/logout.php', {
        method: 'POST',
        credentials: 'include', // Incluir cookies
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.success) {
        alert('La sesión ha expirado. Serás redirigido al login.');
        navigate('/'); // Redirigir al login
      } else {
        alert('Error al cerrar la sesión');
      }
    } catch (error) {
      console.error('Error al cerrar la sesión:', error);
    }
  }, [navigate]);

  const timerRef = useRef(null);

  // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
  const resetTimer = useCallback(async () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
    }, 1800000); // 30 minutos en milisegundos
  }, [cerrarSesion]);

  useEffect(() => {
    // Agregar eventos para detectar actividad del usuario
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('scroll', resetTimer);

    resetTimer(); // Establecer el temporizador al montar el componente

    // Limpiar eventos y temporizador al desmontar el componente
    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('scroll', resetTimer);
    };
  }, [resetTimer]); // Incluir resetTimer como dependencia


  return (
    <div className='body_home'>

      {isLoading ? (
        <div className={`loader ${fadeOut ? 'loader-hidden' : ''}`}>
          <div className="logo">
            <img src={`${process.env.PUBLIC_URL}/assets/images/LOGO_no_bg.png`} alt="Logo" />
          </div>
        </div>
      ) : (
        <main className="home">

          <div className="left-section">
            <iframe width="100%" height="auto" src="https://www.youtube.com/embed/P-wLmlxtqrA?si=YALnhb5cytcaW0A3" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            <iframe width="100%" height="auto" src="https://www.youtube.com/embed/dhst1wn7KPo?si=NU8MEh3GAg_VazCK" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          </div>

          <div className="center-section">
            <h2 className="section-title">Bienvenidos a Nuestra Clínica</h2>
            <p className="intro-text-home">
              En nuestra clínica, nos comprometemos a ofrecer atención médica integral y de calidad, adaptada a las necesidades de cada paciente. Nuestro equipo de profesionales está disponible para brindarte el mejor servicio en el momento que lo necesites.
            </p>

            <div className="services">
              <div className="service-card">
                <h3><Link to='/consultas-virtuales'>Consultas Virtuales</Link></h3>
                <p>Recibe atención médica desde la comodidad de tu hogar con nuestras consultas virtuales en medicina general, psicología y más.</p>
              </div>
              <div className="service-card">
                <h3><Link to='/medicina-general'>Medicina General</Link></h3>
                <p>Diagnósticos precisos y tratamientos efectivos para una amplia gama de afecciones con médicos altamente calificados.</p>
              </div>
              <div className="service-card">
                <h3><Link to='/psicologia'>Psicología</Link></h3>
                <p>Apoyo en tu salud mental: ayuda para superar estrés, ansiedad y otros desafíos emocionales.</p>
              </div>
              <div className="service-card">
                <h3><Link to='/consultas-virtuales'>Otros Servicios</Link></h3>
                <p>Consulta de rutina o atención especializada: cubrimos todas tus necesidades de salud.</p>
              </div>
              <img src={`${process.env.PUBLIC_URL}/assets/images/Consultas_2.jpg`} alt="Consultas Virtuales" className="service-image" />
            </div>
            <p className="contact-info">Para más información, no dudes en contactarnos y programar tu cita.</p>
          </div>


          <div className="right-section">
            <h2>Patrocinadores</h2>
            <ul>
              <li>Patrocinador 1</li>
              <li>Patrocinador 2</li>
              <li>Patrocinador 3</li>
            </ul>
          </div>
        </main>
      )}
    </div>
  );
}
