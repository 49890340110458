import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Gallery() {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('https://medico24x7.com/api/DropBox/getImages.php', {
                    withCredentials: true
                });
                setImages(response.data.images);
            } catch (error) {
                console.error('Error al obtener las imágenes:', error);
            }
        };
        fetchImages();
    }, []);

    return (
        <div>
            <h2>Galería</h2>
            <div>
                {images.map((image) => (
                    <div key={image.id}>
                        <img src={image.url} alt={image.nombre} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Gallery;

