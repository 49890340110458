import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CitasFloating from "./CitasFloating";
import ReciboFloating from "./ReciboFloating";
import '../../css/Admin/Consultas_Admin.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/Admin/check_session_admin_otros.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/Admin');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);


  //Conexión a Base de datos
  const [usuario, setUsuario] = useState([]);

  useEffect(() => {
    // Realizar la solicitud al backend para obtener los datos del usuario
    axios.get('https://medico24x7.com/api/Admin/Nuevo_PHP/Citas_Admin.php', { withCredentials: true }) // Cambia la ruta a tu API
      .then(response => {
        //console.log(response.data);
        setUsuario(response.data); // Supongo que devuelve un array de usuarios
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
  }, []);

  //******Algoritmo de busqueda******
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch("https://medico24x7.com/api/Admin/Nuevo_PHP/Algoritmo_Citas.php", {
        method: "POST",
        credentials: "include", // Para enviar cookies y sesiones
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ input: inputValue })
      });

      const data = await response.json();

      if (!Array.isArray(data)) {
        console.error("La respuesta no es un array:", data);
        return;
      }
      setUsuario(data);

    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    }
  };

  // Función para limpiar la búsqueda y volver a cargar todos los datos
  const handleClearSearch = () => {
    setInputValue(""); // Limpiar el valor de la búsqueda
    // Vuelve a cargar todas las consultas desde la base de datos
    axios.get('https://medico24x7.com/api/Admin/Nuevo_PHP/Citas_Admin.php', { withCredentials: true })
      .then(response => {
        setUsuario(response.data); // Actualizar con todas las consultas
      })
      .catch(error => {
        console.error('Error al obtener las consultas:', error);
      });
  };

  //Codigo para cerrar sesión
  const cerrarSesion = useCallback(async () => {
    try {
      const response = await fetch('https://medico24x7.com/api/Admin/logout_admin.php', {
        method: 'POST',
        credentials: 'include', // Incluir cookies
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.success) {
        alert('La sesión ha expirado.');
        navigate('/Admin'); // Redirigir al login
      } else {
        alert('Error al cerrar la sesión');
      }
    } catch (error) {
      console.error('Error al cerrar la sesión:', error);
    }
  }, [navigate]);

  const timerRef = useRef(null);

  // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
  const resetTimer = useCallback(async () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
    }, 1800000); // 30 minutos en milisegundos
  }, [cerrarSesion]);

  useEffect(() => {
    // Agregar eventos para detectar actividad del usuario
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('scroll', resetTimer);

    resetTimer(); // Establecer el temporizador al montar el componente

    // Limpiar eventos y temporizador al desmontar el componente
    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('scroll', resetTimer);
    };
  }, [resetTimer]); // Incluir resetTimer como dependencia

  const [isFloatingVisible, setIsFloatingVisible] = useState(false);
  const [isFloatingVisibleRecibo, setIsFloatingVisibleRecibo] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);

  const handleButtonClick = (id) => {
    setSelectedToken(id); // Establece el token para el componente flotante
    setIsFloatingVisible(true); // Muestra el componente flotante
  };

  const handleButtonClickRecibo = (id) => {
    setSelectedToken(id); // Establece el token para el componente flotante
    setIsFloatingVisibleRecibo(true); // Muestra el componente flotante
  };

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div className='consultas_admin'>
      <main className='Consultas_admin_main'>
        <div class="parent-consulta">
          <div class="div1-consulta">
            <div className='div-search'>
              <p>Escribe el primer nombre del paciente <br /> que buscas:</p>
              <input
                className='div-search-input'
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Primer Nombre"
              /><br />
              <button onClick={handleSubmit}>Enviar</button><br />
              <button onClick={handleClearSearch}>Limpiar Búsqueda</button>
            </div>
          </div>
          <div class="div2-consulta">
            <ul className="ul-consulta-cont">
              {usuario.map((product) => (
                <section key={product.id} className="li-consulta-cont">
                  <div class="parent-consulta-cont">
                    <div class="div1-consulta-cont">
                      <p className="p-element"><strong>Nombre:</strong> {product.nombre} {product.apellido}</p>
                    </div>
                    <div class="div2-consulta-cont">
                      <p className="p-element"><strong>Fecha:</strong> {product.fecha}</p>
                    </div>
                    <div class="div3-consulta-cont">
                      <p className="p-element"><strong>Hora:</strong> {product.hora}</p>
                    </div>
                    <div class="div4-consulta-cont">
                      <button className="button-element"
                        onClick={() => handleButtonClick(product.id)}>
                        <strong>Información Completa</strong>
                      </button>
                      <br />
                      <button className="button-element"
                        onClick={() => handleButtonClickRecibo(product.token)}>
                        <strong>Recibo</strong>
                      </button>
                    </div>
                  </div>
                </section>
              ))}
            </ul>
          </div>
        </div>
        {isFloatingVisible && (
          <CitasFloating
            token={selectedToken}
            onClose={() => setIsFloatingVisible(false)} // Función para cerrar
          />
        )}
        {isFloatingVisibleRecibo && (
          <ReciboFloating
            token={selectedToken}
            onClose={() => setIsFloatingVisibleRecibo(false)} // Función para cerrar
          />
        )}
      </main>
    </div>
  );
}

export default App;
