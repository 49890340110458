import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../css/Footer.css';

const Footer = () => {
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const hasLoadedBefore = sessionStorage.getItem('hasLoadedBefore');

    if (!hasLoadedBefore) {
      const delay = setTimeout(() => {
        setShowFooter(true);
        sessionStorage.setItem('hasLoadedBefore', 'true');
      }, 10000); // 1000ms = 1 segundo de retraso

      return () => clearTimeout(delay); // Limpieza del timeout al desmontar el componente
    } else {
      setShowFooter(true);
    }
  }, []);

  const isConsultaVirtuales =
    location.pathname === '/pagos' || location.pathname === '/ConsultaChat';

  if (
    location.pathname === '/Admin' ||
    location.pathname === '/Admin/' ||
    location.pathname === '/Consultas_Admin' ||
    location.pathname === '/Citas_Admin' ||
    location.pathname === '/Recibo_Admin' ||
    location.pathname === '/Sugerencias_Admin' ||
    location.pathname === '/AdminDoctor' ||
    location.pathname === '/Consultas_Admin_Doctor' ||
    location.pathname === '/Citas_Admin_Doctor' ||
    location.pathname === '/Blog_Admin'
  ) {
    return null;
  }

  return (
    <>
      {showFooter && (
        <>
          <footer className="footer_">
            <div className="footer-container">
              <div className="footer-logo">
                <img id="logo" src={`${process.env.PUBLIC_URL}/assets/images/LOGO_no_bg.png`} alt="LOGO#1" className="logo-svg" />
              </div>
              <div className="footer-contact">
                <h4>Contáctanos</h4>
                <p>
                  consultame@medico24x7.com<br />
                </p>
              </div>
              {/*<div className="footer-schedule">
                <h4>Horario</h4>
                <p>
                  Lunes - Viernes: 8:00 a.m. - 10:00 p.m.<br />
                  Sábados y Domingos: 9:00 a.m. - 5:00 p.m.
                </p>
              </div>*/}
              <div className="footer-links">
                <h4>Enlaces</h4>
                <p>
                  <Link to="/Sugerencias">Sugerencias</Link><br />
                  <Link to="">FAQ</Link><br />
                  <Link to="">Acuerdo de Confidencialidad</Link><br />
                  <a href="https://www.minsa.gob.pa/" target="_blank" rel="noopener noreferrer">MINSA</a><br />
                </p>
              </div>
            </div>
            <div className="footer-bottom-bar">
              <small>
                © 2024{' '}
                {isConsultaVirtuales ? (
                  <span className="brand-name-sin-estilo">Medico24x7</span>
                ) : (
                  <Link to="/" className="brand-name">
                    Medico24x7
                  </Link>
                )}
                . Todos los derechos reservados.
              </small>
            </div>
          </footer>
        </>
      )}

      {/* 
    <footer>
      {showFooter && (
        <div>
          <footer className="footer">
            <div className="container_consulta_usuario">
              <small>
                © 2024{' '}
                {isConsultaVirtuales ? (
                  <span className="brand-name-sin-estilo">Medico24x7</span>
                ) : (
                  <Link to="/" className="brand-name">
                    Medico24x7
                  </Link>
                )}
                . Todos los derechos reservados.
              </small>
            </div>
          </footer>
        </div>
      )}
    </footer>
    */}
    </>
  );
};

export default Footer;
