import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Sugerencias.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/check_session_pagos.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de sugerencias');
          navigate('/');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);


/*Codigo del Main */
const [sugerencia, setSugerencia] = useState('');
  // const [nombre, setNombre] = useState('');
  // const [correo, setCorreo] = useState('');

  const enviarSugerencia = async () => {
    // Validar los campos
    if (!sugerencia.trim()) {
      alert('Por favor, complete todos los campos obligatorios.');
      return;
    }

    // Crear el objeto con los datos del formulario
    const formData = new FormData();
    // formData.append('nombre', nombre);
    // formData.append('correo', correo);
    formData.append('sugerencia', sugerencia);

    try {
      const response = await axios.post('https://medico24x7.com/api/guardar_sugerencia.php', formData, { withCredentials: true });
      const data = response.data;

      if (data.success) {
        alert('Sugerencia guardada correctamente.');
        // Resetear el formulario
        setSugerencia('');
        // setNombre('');
        // setCorreo('');
      } else {
        alert('Error al guardar la sugerencia: ' + data.message);
      }
    } catch (error) {
      console.error('Error al enviar la sugerencia:', error);
    }
  };


//Codigo para cerrear sesion despues de 30 minutos
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/logout.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/'); // Redirigir al login
      setIsAuthenticated(false);
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);

const timerRef = useRef(null);
  
// Define resetTimer con useCallback para evitar recrearlo en cada renderizado
const resetTimer = useCallback( async() => {
  clearTimeout(timerRef.current);
  timerRef.current = setTimeout(() => {
    cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
  }, 1800000); // 30 minutos en milisegundos
}, [cerrarSesion]);

useEffect(() => {
  // Agregar eventos para detectar actividad del usuario
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keypress', resetTimer);
  window.addEventListener('scroll', resetTimer);

  resetTimer(); // Establecer el temporizador al montar el componente

  // Limpiar eventos y temporizador al desmontar el componente
  return () => {
    clearTimeout(timerRef.current);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keypress', resetTimer);
    window.removeEventListener('scroll', resetTimer);
  };
}, [resetTimer]); // Incluir resetTimer como dependencia

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div>
      <main id='sugerencias'>
      <div className="suggestions-section">
      <h1 className="title">Sugerencias y Comentarios</h1>
      <p className="intro-text">Nos encantaría saber cómo podemos mejorar nuestros servicios. 
        Por favor, deja tus sugerencias o comentarios a continuación.</p>
      
      <form className="suggestion-form" onSubmit={(e) => e.preventDefault()}>

        <div className="form-group">
          <label htmlFor="sugerencia">Tu Sugerencia:</label>
          <textarea
            id="sugerencia"
            name="sugerencia"
            placeholder="Escribe tu sugerencia o comentario aquí"
            rows="5"
            value={sugerencia}
            onChange={(e) => setSugerencia(e.target.value)}
          ></textarea>
        </div>

        <div className="form-group">
          <button type="button" className="submit-button" onClick={enviarSugerencia}>
            Enviar Sugerencia
          </button>
        </div>
      </form>
    </div>
      </main>
    </div>
  );
}

export default App;