import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/RedesSociales.css';

function App() {
  const location = useLocation(); // Usa useLocation para obtener la ruta actual

  const [isMovable, setIsMovable] = useState(false); // Controla si el botón es movible
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const initialMouse = useRef({ x: 0, y: 0 });
  const isDragging = useRef(false);
  const pressTimer = useRef(null); // Temporizador para detectar mantener presionado

  // Activa o desactiva el modo movible al hacer doble clic
  const handleDoubleClick = () => {
    setIsMovable(!isMovable);
  };

  //Reiniciar la posición inicial al cargar y ajustar la posición si cambia el tamaño de la ventana 
  useEffect(() => {
    const updateInitialPosition = () => {
      setPosition({
        top: window.innerHeight * 0.85,
        left: 0,
      });
    };

    // Establece la posición inicial al cargar
    updateInitialPosition();

    // Ajusta la posición si cambia el tamaño de la ventana
    window.addEventListener('resize', updateInitialPosition);

    return () => {
      window.removeEventListener('resize', updateInitialPosition);
    };
  }, []);

  // Activa el modo movible al mantener presionado
  const handlePressStart = (e) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;

    pressTimer.current = setTimeout(() => {
      setIsMovable(true);
      isDragging.current = true;
      initialMouse.current = { x: clientX, y: clientY };
    }, 300); // Activa el modo movible después de 300ms
  };

  // Maneja el movimiento del botón
  const handleMove = (e) => {
    if (isDragging.current) {
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;

      const deltaX = clientX - initialMouse.current.x;
      const deltaY = clientY - initialMouse.current.y;

      setPosition((prev) => ({
        top: Math.max(0, Math.min(window.innerHeight - 100, prev.top + deltaY)),
        left: Math.max(0, Math.min(window.innerWidth - 100, prev.left + deltaX)),
      }));

      initialMouse.current = { x: clientX, y: clientY };
    }
  };

  // Termina el movimiento al soltar el botón o dedo
  const handlePressEnd = () => {
    clearTimeout(pressTimer.current);
    isDragging.current = false;
    setIsMovable(false);
  };

  // Añade listeners para eventos táctiles y de mouse
  useEffect(() => {
    document.addEventListener("mousemove", handleMove);
    document.addEventListener("mouseup", handlePressEnd);
    document.addEventListener("touchmove", handleMove);
    document.addEventListener("touchend", handlePressEnd);

    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handlePressEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handlePressEnd);
    };
  }, []);

  return (
    <>
      {/* Barra de redes sociales */}
      {["/admin", "/admin/", "/Admin/", "/Admin", "/Consultas_Admin", "/Citas_Admin", "/Recibo_Admin", "/Sugerencias_Admin", "/Login_Admin", "/Registro_Admin"].includes(location.pathname) ? (
        <>
        </>
      ) : (
        ["/Consultas", "/Citas_usuario", "/Recibo_pagos"].includes(location.pathname) ? (
          <>
          </>
        ) : (
          <>
            <button className="social-bar"
              onDoubleClick={handleDoubleClick}
              onMouseDown={handlePressStart}
              onTouchStart={handlePressStart}
              onMouseUp={handlePressEnd}
              onTouchEnd={handlePressEnd}
              style={{
                backgroundColor: "rgba(104, 60, 60, 0.41)",
                position: "absolute",
                top: `${position.top}px`,
                left: `${position.left}px`,
                border: "none",
                fontSize: "24px",
                cursor: isMovable ? "move" : "default",
                transition: "all 0.2s ease",
              }}
            >
              <ul>
                <li>
                  <a href="https://web.facebook.com/medico24x7" target="_blank" className="icon facebook" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/md24x7/" target="_blank" className="icon instagram" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </button>
          </>
        )
      )}
    </>
  );
}

export default App;