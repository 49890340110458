import React, { useState } from 'react';
import axios from 'axios';
import './styles.css';

function UploadForm() {
    const [images, setImages] = useState([]); // Almacena los archivos seleccionados

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files); // Convierte FileList a Array

        // Evita duplicados al agregar archivos
        const uniqueFiles = selectedFiles.filter(
            (file) => !images.some((img) => img.name === file.name)
        );

        setImages((prevImages) => [...prevImages, ...uniqueFiles]); // Agrega nuevas imágenes sin reemplazar las existentes
    };

    const handleUpload = async (e) => {
        e.preventDefault();
    
        if (images.length === 0) {
            alert("Por favor, selecciona al menos una imagen.");
            return;
        }
    
        try {
            const uploadPromises = images.map(async (image) => {
                const formData = new FormData();
                formData.append('image', image);
    
                // Usamos axios para hacer la solicitud POST
                const response = await axios.post('https://medico24x7.com/api/DropBox/upload_prueba.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Asegúrate de que el tipo de contenido esté correctamente configurado
                    },
                    withCredentials: true, // Esto asegura que se envíen las cookies si es necesario
                });
    
                // Verifica si la respuesta es JSON válida
                const data = response.data;
                if (data.success) {
                    console.log(`Imagen ${image.name}: subida con éxito.`);
                }
                else {throw new Error(data.message);}
            });
    
            // Espera a que todas las imágenes se suban
            await Promise.all(uploadPromises);
    
            alert("Imágenes subidas con éxito.");
            setImages([]); // Limpiar las imágenes cargadas
    
        } catch (error) {
            alert('Error al subir las imágenes: ' + error.message);
            console.error(error);
        }
    };

    return (
        <div className='Upload'>
        <form onSubmit={handleUpload} className="upload-form">
            <input
                type="file"
                onChange={handleFileChange}
                multiple
                className="file-input"
            />
            <button type="submit" className="upload-button">Subir Imágenes</button>
            <div className="image-list">
                {images.map((image, index) => (
                    <div key={index} className="image-item">
                        <span>{image.name}</span>
                    </div>
                ))}
            </div>
        </form>
        </div>
    );
}

export default UploadForm;