import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Citas.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/check_session_pagos.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
          setValue(response.data.precio);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);

  // Estados para manejar los datos del formulario
  const [servicio, setServicio] = useState('');
  const [correo_doctor, setCorreo_Doctor] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [doctores, setDoctores] = useState([]); // Estado para la lista de doctores
  const [doctor, setDoctor] = useState(""); // Estado para el doctor seleccionado

  // Función para manejar el cambio de servicio
  const handleServicioChange = async (e) => {
    e.preventDefault(); // Previene el comportamiento predeterminado del navegador

    const selectedServicio = e.target.value;
    setServicio(selectedServicio);

    // Hacer solicitud al backend para obtener los doctores que brindan el servicio seleccionado
    try {
      const response = await axios.get(`https://medico24x7.com/api/getDoctores.php?servicio=${selectedServicio}`, { withCredentials: true });
      setDoctores(response.data); // Actualizar la lista de doctores con la respuesta
      setDoctor(""); // Limpiar el doctor seleccionado
    } catch (error) {
      console.error("Error al obtener los doctores:", error);
    }
  };

  //obtener horas disponibles
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableHours, setAvailableHours] = useState([]);
  const [selectedHour, setSelectedHour] = useState('');

  // Obtener horas disponibles cuando se selecciona una fecha
  useEffect(() => {
    if (selectedDate) {
      const fetchAvailableHours = async () => {
        const formattedDate = selectedDate.toISOString().split('T')[0];
        const formattedCorreo = correo_doctor;

        try {
          const response = await axios.get(
            `https://medico24x7.com/api/obtener_horas_disponibles.php?fecha=${formattedDate}&correo=${formattedCorreo}`
          );
          setAvailableHours(response.data);
        } catch (error) {
          console.error('Error al obtener las horas disponibles', error);
          console.log(formattedCorreo);
        }
      };

      fetchAvailableHours();
    }
  }, [selectedDate, correo_doctor]);

  const handleHourSelect = (e) => {
    setSelectedHour(e.target.value);
  };


  //obtener fechas disponibles
  const [availableFecha, setAvailableFecha] = useState([]);

  // Obtener horas disponibles cuando se selecciona una fecha
  useEffect(() => {
    if (correo_doctor) {
      const fetchAvailableFecha = async () => {
        const formattedCorreo = correo_doctor;

        try {
          const response = await axios.get(
            `https://medico24x7.com/api/obtener_fechas_disponibles.php?correo=${formattedCorreo}`
          );
          setAvailableFecha(response.data);
        } catch (error) {
          console.error('Error al obtener las horas disponibles', error);
        }
      };

      fetchAvailableFecha();
    }
  }, [correo_doctor]);

  // Función para verificar si una fecha está disponible
  const isDateUnavailable = (date) => {
    const dateString = date.toISOString().split('T')[0]; // Convierte a string 'YYYY-MM-DD'
    return availableFecha.includes(dateString);
  };


  //Pagos
  //Constantes
  const [metodoPago, setMetodoPago] = useState('');
  const [paypalClientId, setPaypalClientId] = useState('');
  const [loadingPaypal, setLoadingPaypal] = useState(false);
  const [Value, setValue] = useState('');

  // Configurar PayPal
  useEffect(() => {
    if (metodoPago === 'paypal' && paypalClientId) {
      //Producción: https://www.paypal.com/sdk/js
      //Sanbox: https://www.sandbox.paypal.com/sdk/js
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=USD`;
      script.async = true;
      script.onload = () => {
        window.paypal.Buttons({
          createOrder: async (data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: Value, // Asegúrate de que Value es válido
                },
              }],
            });
          },
          onApprove: async (data, actions) => {
            try {
              const details = await actions.order.capture();
              const response = await axios.post(
                'https://medico24x7.com/api/pago_paypal.php',
                new URLSearchParams({
                  monto: Value,
                  servicio: servicio,
                  mensaje: mensaje,
                  fecha: selectedDate.toISOString().split('T')[0],
                  hora: selectedHour,
                  correo_doctor: correo_doctor,
                  orderID: details.id,
                  payerID: details.payer.payer_id,
                }).toString(),
                {
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                  withCredentials: true,
                }
              );

              const result = response.data;
              if (result.success) {

                const actualizarSesion = () => {
                  return axios.post('https://medico24x7.com/api/actualizar_sesion_paypal.php', {
                    metodo_de_pago: 'Paypal o Tarjeta', // Puedes enviar información adicional aquí si es necesario
                    monto: Value,
                  }, { withCredentials: true }) // Asegúrate de que las opciones están aquí
                    .then(response => {
                      if (response.data.status === 'success') {
                        alert('Todo se envió correctamente');
                      } else {
                        console.error(response.data.message);
                        alert('Error al actualizar la sesión. Por favor, intenta de nuevo.');
                      }
                    })
                    .catch(error => {
                      console.error('Error al actualizar la sesión:', error);
                      alert('Ocurrió un error al procesar tu pago.');
                    });
                };

                await actualizarSesion();
                alert('Su solicitud de cita ha sido enviada correctamente.');
                setServicio('');
                setMensaje('');
                setSelectedDate('');
                setSelectedHour('');
                setDoctores('');
                setMetodoPago('');
                //navigate('/');
              } else {
                alert('Error al enviar la solicitud: ' + result.message);
              }
            } catch (error) {
              console.error('Error en el envío de datos:', error);
              alert('Ocurrió un error al procesar su solicitud.');
            }
          },
          onError: (err) => {
            console.error('Error en el pago: ', err);
            alert('Hubo un error al procesar el pago, por favor intenta de nuevo.');
          },
        }).render('#paypal-container');
      };
      document.head.appendChild(script);
    }
  }, [metodoPago, paypalClientId, Value, servicio, mensaje, selectedDate, selectedHour, correo_doctor]);

  const [image, setImage] = useState(null); // Almacena los archivos seleccionados

  const handleFileChange = (e) => {
    e.preventDefault(); // Previene el comportamiento predeterminado del navegador)

    const selectedFile = e.target.files[0];
    if (selectedFile) {
      console.log('Archivo seleccionado:', selectedFile); // Verifica el archivo
      setImage(selectedFile);
    }
  };

  const handleRemoveImage = (e) => {
    e.preventDefault(); // Previene el comportamiento predeterminado del navegador
    setImage(null); // Restablece el estado de la imagen a null
  };


  const handleMetodoPagoChange = async (e) => {
    e.preventDefault();

    // Validar campos obligatorios
    if (!servicio || !mensaje || !selectedDate || !selectedHour) {
      alert('Por favor, complete todos los campos obligatorios.');
      return;
    }

    const metodo = e.target.value;
    setMetodoPago(metodo);

    if (metodo === 'paypal') {
      // Obtener el client_id desde el servidor
      setLoadingPaypal(true);
      axios.get('https://medico24x7.com/api/obtener_paypal_config.php', { withCredentials: true })
        .then(response => {
          setPaypalClientId(response.data.client_id);
          setLoadingPaypal(false);
        })
        .catch(error => {
          console.error('Error al cargar los datos de PayPal:', error);
          alert('Hubo un problema al configurar PayPal, por favor intenta de nuevo.');
          setLoadingPaypal(false);
        });
    }
  };

  const handleTransferenciaSubmit = async (event) => {
    event.preventDefault(); // This prevents form refresh

    // Validate required fields
    if (!servicio || !mensaje || !selectedDate || !selectedHour || !image) {
      alert('Por favor, complete todos los campos obligatorios incluyendo el comprobante.');
      return;
    }

    console.log('Imagen:', image);

    try {
      // Create FormData object
      const formData = new FormData();
      formData.append('metodo_de_pago', 'Transferencia');
      formData.append('monto', Value);
      formData.append('servicio', servicio);
      formData.append('mensaje', mensaje);
      formData.append('fecha', selectedDate.toISOString().split('T')[0]);
      formData.append('hora', selectedHour);
      formData.append('correo_doctor', correo_doctor);
      formData.append('comprobante_tranferencia', image);

      const response = await axios.post(
        'https://medico24x7.com/api/pago_yappy_y_tranferencia.php',
        formData,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === 'success') {
        alert('Su solicitud de cita ha sido enviada correctamente.');
        // Reset form
        setServicio('');
        setMensaje('');
        setDoctores([]);
        setSelectedDate(null);
        setSelectedHour('');
        setImage(null);
        setMetodoPago('');
      } else {
        throw new Error(response.data.message || 'Error al enviar los datos');
      }
    } catch (error) {
      console.error('Error en el envío:', error);
      alert('Ocurrió un error al procesar su solicitud. Por favor, intente nuevamente.');
    }
  };

  const handleYappySubmit = async (event) => {
    event.preventDefault(); // This prevents form refresh

    // Validate required fields
    if (!servicio || !mensaje || !selectedDate || !selectedHour || !image) {
      alert('Por favor, complete todos los campos obligatorios incluyendo el comprobante.');
      return;
    }

    try {
      // Create FormData object
      const formData = new FormData();
      formData.append('metodo_de_pago', 'Yappy');
      formData.append('monto', Value);
      formData.append('servicio', servicio);
      formData.append('mensaje', mensaje);
      formData.append('fecha', selectedDate.toISOString().split('T')[0]);
      formData.append('hora', selectedHour);
      formData.append('correo_doctor', correo_doctor);
      formData.append('comprobante_tranferencia', image);

      const response = await axios.post(
        'https://medico24x7.com/api/pago_yappy_y_tranferencia.php',
        formData,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === 'success') {
        alert('Su solicitud de cita ha sido enviada correctamente.');
        // Reset form
        setServicio('');
        setMensaje('');
        setDoctores([]);
        setSelectedDate(null);
        setSelectedHour('');
        setImage(null);
        setMetodoPago('');
      } else {
        throw new Error(response.data.message || 'Error al enviar los datos');
      }
    } catch (error) {
      console.error('Error en el envío:', error);
      alert('Ocurrió un error al procesar su solicitud. Por favor, intente nuevamente.');
    }
  };

  // In the JSX for the transfer form, update the form element:
  const TransferenciaForm = () => (
    <form
      id="form-transferencia"
      className="formulario-pagos"
    >
      <h3>Pago con Transferencia Bancaria</h3>
      <p>Por favor realiza la transferencia a una de estas cuentas:</p>
      <br />
      <p>Banco General: Enrique Puentes</p>
      <p>Cuenta Ahorros: 04-37-98-032556-7</p>
      <br />
      <p>Scotia Bank (Bank of Nova Scotia): Enrique Puentes Ruiz.</p>
      <p>Cuenta Ahorros: 200000122373</p>
      <br />
      <p>Por favor, no escriba nada en descripción o comentarios.</p>
      <fieldset>
        <label htmlFor="comprobante_tranferencia" className="files">
          <strong>Sube foto o captura del comprobante:</strong>
        </label>
        <input
          type="file"
          onChange={handleFileChange}
          className="file-input"
        />
        {image && (
          <div className="image-list">
            <div className="image-item">
              <p>Archivo seleccionado: {image.name}</p>
              <img
                src={URL.createObjectURL(image)}
                alt="Vista previa"
                style={{ width: '200px', height: 'auto' }}
              />
              <br />
              <button
                type="button"
                className="remove-button"
                onClick={handleRemoveImage}
              >
                Eliminar
              </button>
            </div>
          </div>
        )}
      </fieldset>
      <button
        type="button"
        onClick={handleTransferenciaSubmit}
      >
        Enviar Cita
      </button>
    </form>
  );

  const YappyForm = () => (
    <form
      id="form-yappy"
      className="formulario-pagos"
      >
      <h3>Pago con Yappy</h3>
      <p>Por favor realiza la transferencia al siguiente número de celular:</p>
      <br />
      <p>Número de celular: 6475-3624 (Enrique Puentes).</p>
      <br />
      <p>Por favor, no escriba nada en descripción o comentarios.</p>
      <fieldset>
        <label htmlFor="comprobante_tranferencia" className="files"><strong>Sube foto o captura del comprobante:</strong></label>
        <input
          type="file"
          onChange={handleFileChange}
          className="file-input"
        />
        {image && (
          <div className="image-list">
            <div className="image-item">
              <p>Archivo seleccionado: {image.name}</p>
              <img
                src={URL.createObjectURL(image)}
                alt="Vista previa"
                style={{ width: '200px', height: 'auto' }}
              />
              <br />
              <button
                type="button"
                className="remove-button"
                onClick={handleRemoveImage}
              >
                Eliminar
              </button>
            </div>
          </div>
        )}
      </fieldset>
      <button 
      type="button" 
      onClick={handleYappySubmit}
    >
      Enviar Cita
    </button>
    </form>
  );

  // Si el usuario no está autenticado, no mostrar nada
  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className='body_citas'>
      <main id='citas'>

        {/* Información de contacto y mapa */}
        <div className="consulta-virtual">
          <form className='formularioPresencial'>
            <fieldset>
              <h2>Información</h2>
              {/* <p><strong>Precio de la Cita:</strong> $10/hora</p> */}
              <fieldset>
                <div id='info-citas'>
                  <p id='p-consultas'>"En esta página puedes reservar una cita para una consulta presencial en la clínica. Si necesitas una consulta médica de manera inmediata, también ofrecemos "Consultas Virtuales" para tu comodidad."</p>
                </div>
              </fieldset><p><strong>Precio de Consulta Virtuales:</strong></p>
              <table border="1" cellSpacing="10" cellPadding="5" id="consultas_virtuales_table">
                <thead>
                  <tr>
                    <th>Disciplina</th>
                    <th>Precio</th>
                    <th>Tiempo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Medicina General</td>
                    <td>$10</td>
                    <td>1 hora</td>
                  </tr>
                  <tr>
                    <td>Psicología</td>
                    <td>$40</td>
                    <td>1 hora</td>
                  </tr>
                  <tr>
                    <td>Medicina Interna</td>
                    <td>$40</td>
                    <td>1 hora</td>
                  </tr>
                </tbody>
              </table>

              <p><strong>Horario de citas:</strong> Lunes - Viernes de 8:00 a.m. - 10:00 p.m. / Sábado y Domingo de 9:00 a.m. - 5:00 p.m.</p>
              <p><strong>Número de Celular:</strong> +507 6475-3624 (solo para mensajes de WhatsApp)</p>
              <p><strong>Correo:</strong> <a href="mailto:consultame@medico24x7.com">consultame@medico24x7.com</a></p>
              <p><strong>Ubicación:</strong> Panamá, Provincia: Coclé, Distrito: Penonomé, Quintas El Encanto, Casa 19A</p>
            </fieldset>
            <div className="mapa">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.7909084430216!2d-80.34508605888207!3d8.519671051510636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fac4fb708547bb9%3A0x8929096f32620ac8!2smedico24x7!5e0!3m2!1ses-419!2spa!4v1724913683959!5m2!1ses-419!2spa"
                width="450"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Mapa de Medico24x7" // Aquí agregamos el atributo title
              ></iframe>
            </div>
          </form>
        </div>

        {/* Formulario de solicitud de cita */}
        <div className="cont-formulario">
          <h1 className="form-title">Solicitud Para Consulta Presencial</h1>
          <form className="formularioPresencial">
            {/* Campo para seleccionar el tipo de servicio */}
            <fieldset>
              <div className="form-group">
                <label htmlFor="servicio">Tipo de Servicio:</label>
                <select id="servicio" name="servicio" value={servicio} onChange={handleServicioChange} required>
                  <option value="" disabled>
                    Seleccione el servicio
                  </option>
                  <option value="consulta_general">Medicina General</option>
                  <option value="psicologia">Psicología</option>
                  <option value="medicina_interna">Medicina Interna</option>
                </select>
              </div>

              {doctores.length > 0 ? (
                <div className="form-group">
                  <label htmlFor="doctor">Seleccione el Doctor:</label>
                  <select id="doctor" name="doctor" value={doctor} onChange={(e) => {
                    setDoctor(e.target.value);

                    // Busca el doctor seleccionado en la lista de doctores usando el correo
                    const selectedDoctor = doctores.find((doc) => doc.correo === e.target.value);

                    // Si el doctor es encontrado, actualiza el estado de correo_doctor
                    if (selectedDoctor) {
                      setCorreo_Doctor(selectedDoctor.correo);
                    }
                  }} required>
                    <option value="" disabled>
                      Seleccione el doctor
                    </option>
                    {doctores.map((doc) => (
                      <option key={doc.id} value={doc.correo}>
                        {doc.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <p style={{ fontWeight: 'bold', color: 'red' }}>
                  No hay doctores disponibles para esta especialidad.
                </p>
              )}

              {/* Selección de fecha */}
              {doctor && (
                <div className="form-group">
                  <label>Selecciona una Fecha</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    filterDate={(date) => !isDateUnavailable(date)} // Filtrar fechas no disponibles
                  />
                </div>
              )}

              {/* Selección de hora */}
              {selectedDate && availableHours.length > 0 && (
                <div className="form-group">
                  <label>Selecciona una Hora</label>
                  <select value={selectedHour} onChange={handleHourSelect}>
                    <option value="" disabled>
                      Selecciona una hora
                    </option>
                    {availableHours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Campo para el motivo de la cita */}
              <div className="form-group">
                <label htmlFor="mensaje">Motivo de la Consulta:</label>
                <textarea
                  id="mensaje"
                  name="mensaje"
                  rows="4"
                  value={mensaje}
                  onChange={(e) => setMensaje(e.target.value)}
                  placeholder="Describa brevemente el motivo de la cita..."
                  required
                ></textarea>
              </div>

              {/* Botón para enviar el formulario */}
              <div className="form-group">
                {doctores.length > 0 && (
                  <>
                    {/*Metodos de pago*/}
                    <label>Metodo de Pago</label>
                    <select id="metodo-pago" value={metodoPago} onChange={handleMetodoPagoChange}>
                      <option value="">Seleccione el método de pago...</option>
                      <option value="paypal">PayPal y Tarjetas Débito/Crédito</option>
                      <option value="transferencia">Transferencia Bancaria (Solo Panamá)</option>
                      <option value="yappy">Yappy (Solo Panamá)</option>
                    </select>

                    {/*Formulario para PayPal*/}
                    {metodoPago === 'paypal' && (
                      <div id="paypal-container" className="formulario-pagos">
                        {loadingPaypal && <p>Cargando PayPal...</p>}
                      </div>
                    )}

                    {/*Formulario para Transferencia Bancaria*/}
                    {metodoPago === 'transferencia' && <TransferenciaForm />}


                    {/*Formulario para Yappy*/}
                    {metodoPago === 'yappy' && <YappyForm />}
                  </>
                )}
              </div>
            </fieldset>
          </form>
        </div>
      </main>
    </div>
  );
}

export default App;